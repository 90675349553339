import { render, staticRenderFns } from "./EditAdditionalStatistics.vue?vue&type=template&id=f36cd28c&scoped=true"
import script from "./EditAdditionalStatistics.vue?vue&type=script&lang=js"
export * from "./EditAdditionalStatistics.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36cd28c",
  null
  
)

export default component.exports