<template>
  <v-card>
    <v-card-title>
      {{'Edit an additional statistic'}}
    </v-card-title>
    <v-divider class="ma-1"/>
    <v-card-text>
      <v-container class="pa-0">
        <v-form ref="form" v-model="valid">
          <v-row no-gutters justify="center">
            <v-col class="pl-2 pr-2"  md="4" sm="6">
              <v-text-field :label="$t('generic.lang_name')" outlined :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]" v-model="name"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            @focus="showTouchKeyboard"
              ></v-text-field>
            </v-col>
            <v-col class="pl-2 pr-2"  md="5" sm="6">
              <fontawesome-picker  :field-label="'icon'" v-model="icon" :rules="[v=>v&&!!v || $t('generic.lang_requiredField')]"/>
            </v-col>
            <v-col class="pl-2 pr-2"  md="5" sm="6">
              <strong>{{$t('erp.lang_warecreate_color')}}:</strong>
              <swatches
                  inline v-model="color"
                  background-color="transparent"
              ></swatches>
            </v-col>

            <v-col class="pl-2 pr-2"  md="5" sm="6">

              <v-switch
                  v-model="status" outlined :label="$t('generic.lang_status')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
        <v-row justify="end">
          <v-btn :disabled="!valid"
                 :loading="loading"
                 @click="update"
                 color="success"
                 large
          >
            {{ $t('generic.lang_edit') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns"
import FontawesomePicker from "@/components/common/iconPicker";
import swatches from "vue-swatches"
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "@/config";

export default {
  name: "EditAdditionalStatisticsComponent",
  components:{FontawesomePicker,swatches},
  mixins: [mixin],
  data(){
    return{
      valid:false,
      icon:null,
      name:null,
      color:null,
      status:true,
      loading:false,
    }
  },
  methods:{
    update(){
      if(!this.$refs.form.validate()){
        return;
      }

      if(!this.color){
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_pleaseSelectColorIsRequired'),
          color: "warning"
        });
        return ;
      }

      this.loading=true;

      this.axios.post(ENDPOINTS.SETTINGS.ADDITIONALSTATISTICS.UPDATE,{
        uuid:this.$route.params.uuid,
        icon:this.icon,
        name:this.name,
        color:this.color,
        status:this.status,
      }).then(res=>{
        if(res.data.status==='SUCCESS'){

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_updatedSuccessfully'),
            color: "success"
          });
          this.$refs.form.reset()
          this.$router.go(-1);
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      })

    },
    getData(){
      this.loading=true;

      this.axios.post(ENDPOINTS.SETTINGS.ADDITIONALSTATISTICS.GET,{
        uuid:this.$route.params.uuid,
      }).then(res=>{
        if(res.data.status==='SUCCESS'){
          this.icon=res.data.data[0].icon
          this.name=res.data.data[0].name
          this.color=res.data.data[0].color
          this.status=res.data.data[0].status
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(()=>{
        this.loading=false;
      })

    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>